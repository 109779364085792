import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <div className="page-not-found">
        <div className="not-found-img-wrap">
          <img src={require("../assets/not-found.webp")} alt="page-not-found" />
        </div>
        <h3 className="not-found-title">Page Not Found!</h3>
        <p className="not-found-content">
          The page you're looking for seems to be missing.
        </p>
        <Link to="/" className="not-found-btn">
          Back To Home Page
        </Link>
      </div>
    </>
  );
};

export default PageNotFound;
