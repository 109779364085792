import React, { useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { useAuth } from "./AuthContext";

export default function Register() {
  const [isReferralFromUrl, setIsReferralFromUrl] = useState(false);
  useEffect(() => {
    let str = window.location.href;
    const letters = str.includes("?");
    if (letters) {
      console.log(
        window.location.href.split("=")[1],
        "window.location.href",
        letters
      );
      // setReferalGet(window.location.href.split("=")[1]) ;
      let locationData = window.location.href.split("=")[1];
      let formData = { ...formValue, ...{ referral_code: locationData } };
      setFormValue(formData);
      setIsReferralFromUrl(true);
    } else {
      // console.log("asfasdfasdfasdfasdfsd");
    }
  }, []);

  const { register } = useAuth();
  const initialFormValue = {
    email: "",
    password: "",
    confirmPassword: "",
    referral_code: "",
  };

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);
  const [isChecked, setIschecked] = useState(false);
  const [Terms, setTerms, Termsref] = useState(false);
  const [termsValidation, setTermsValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");

  const { email, password, confirmPassword, referral_code } = formValue;

  const navigate = useNavigate();
  const createUid = uuidv4();
  const createdUuid = createUid.split("-")[0].toString();

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const handleTerms = (event) => {
    setIschecked(event.target.checked);
    setTerms(event.target.checked);
    setTermsValidation(!event.target.checked);
  };

  const validate = (values) => {
    let errors = {};
    const username = values.email.split("@")[0];

    if (!values.email) {
      errors.email = "Email is a required field !";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address !";
      setemailValidate(true);
    } else if (username.length < 3 || username.length > 150) {
      errors.email = "Email username must be between 3-150 characters long";
      setemailValidate(true);
    } else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
      errors.email =
        " Only letters (a-z), numbers (0-9), and periods (.) are allowed in the username";
      setemailValidate(true);
    } else if (!/[a-zA-Z]/.test(username)) {
      errors.email = "Email username must contain at least one letter (a-z)";
      setemailValidate(true);
    } else if (values.email.length > 250) {
      errors.email = "Email address is too long !";
      setemailValidate(true);
    } else if (values.password == "") {
      setemailValidate(false);
      setpasswordValidate(true);
      errors.password = "Password is required !";
    } else if (values.password.length < 15 || values.password.length > 30) {
      setpasswordValidate(true);
      errors.password = "Password should not below 15 above 30 letters !";
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character !";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character !";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at one digit character !";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character !";
    } else if (!values.confirmPassword) {
      setpasswordValidate(false);
      errors.confirmPassword = "Confirm password is a required field !";
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = "Password and Confirm password does not match";
      setconfirmPasswordValidate(true);
    } else if (!Terms) {
      setconfirmPasswordValidate(false);
      errors.terms = "Terms is a required field !";
      setTermsValidation(true);
    } else {
      errors.terms = "";
      setconfirmPasswordValidate(false);
      setpasswordValidate(false);
      setemailValidate(false);
      setTermsValidation(false);
    }
    setvalidationnErr(errors);

    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const formSubmit = async (payload) => {
    let errros = validate(formValue);
    formValue["UUID"] = createdUuid;
    console.log(formValue, "=-=-=-formvalue=-=-");
    if (
      emailValidateref.current === false &&
      passwordValidateref.current === false &&
      confirmPasswordValidateref.current === false &&
      Terms == true
    ) {
      console.log(formValue);
      var data = {
        apiUrl: apiService.signup,
        payload: formValue,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setFormValue(initialFormValue);
      setbuttonLoader(false);
      if (resp.status == true) {
        toast.success(resp.Message);
        // localStorage.setItem("useremail", formValue.email);
        sessionStorage.setItem("useremail", formValue.email);
        navigate("/verification");
      } else {
        toast.error(resp.Message);
      }
    } else {
    }
  };

  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>
      </main>

      <div>
        {/* <Header /> */}
        {/* <div className="container ">
          <img src={Logo} alt="" className="login-logo" />
        </div> */}

        <div className="register">
          <div className="container">
            <div className="row reg-container">
              <div className="col-lg-6 left-reg ">
                <div className="reg-left-flex">
                  <div className="reg-left-title">
                    Sign Up and Start Earning Rewards on Every Trade!
                  </div>
                  <div className="reg-gift">
                    <img
                      src={require("../assets/reg-gift.webp")}
                      alt="gift-icon"
                    />
                  </div>
                  <div className="reg-left-content">
                    Join now to earn rewards, access top tools, and boost your
                    trading potential. Start today!
                  </div>
                </div>
              </div>

              <div className="col-lg-6 right-reg">
                <span class="heading">Welcome To Voltrix Crypt</span>
                {/* <div class="head-log">Register with your Email</div> */}

                <div className="input-groups mt-4 mb-3">
                  <h6 className="input-label mb-3">Email</h6>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    className="input-field"
                    placeholder="Please enter your email address"
                  />
                  {validationnErr && validationnErr.email && (
                    <p className="errorcss">{validationnErr.email}</p>
                  )}
                </div>

                <div className="input-groups icons my-4">
                  <h6 className="input-label mb-3">Password</h6>
                  <div className="flex_input_posion">
                    <input
                      type={inputType}
                      name="password"
                      value={password}
                      minLength={15}
                      maxLength={30}
                      onChange={handleChange}
                      className="input-field"
                      placeholder="Please create a password"
                    />
                    {passHide == true ? (
                      <i
                        class="fa-regular fa-eye reg_eye"
                        onClick={() => passwordHide("show")}
                      ></i>
                    ) : (
                      <i
                        class="fa-regular fa-eye-slash reg_eye"
                        onClick={() => passwordHide("hide")}
                      ></i>
                    )}
                  </div>
                  {validationnErr && validationnErr.password && (
                    <p className="errorcss">{validationnErr.password}</p>
                  )}
                </div>

                <div className="input-groups icons my-4">
                  <h6 className="input-label mb-3">Confirm Password</h6>
                  <div className="flex_input_posion">
                    <input
                      type={inputTypeconf}
                      name="confirmPassword"
                      value={confirmPassword}
                      minLength={15}
                      maxLength={30}
                      onChange={handleChange}
                      className="input-field"
                      placeholder="Please re-enter the password"
                    />
                    {passHidconf == true ? (
                      <i
                        class="fa-regular fa-eye reg_eye"
                        onClick={() => passwordHideconf("show")}
                      ></i>
                    ) : (
                      <i
                        class="fa-regular fa-eye-slash reg_eye"
                        onClick={() => passwordHideconf("hide")}
                      ></i>
                    )}
                  </div>
                  {validationnErr && validationnErr.confirmPassword && (
                    <p className="errorcss">{validationnErr.confirmPassword}</p>
                  )}
                </div>

                <div className="input-groups icons">
                  <h6 className="input-label mb-3">Refferal code</h6>
                  <input
                    type="text"
                    name="referral_code"
                    maxLength={11}
                    value={referral_code}
                    disabled={isReferralFromUrl}
                    // readOnly
                    onChange={handleChange}
                    className="input-field"
                    placeholder="Refferal code ( Optional )"
                  />
                </div>

                {/* <div className="terms-new">
                  <div className="terms">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleTerms}
                      id="customCheck"
                      className="checkbox-round"
                    />

                    <label htmlFor="customCheck" className="terms-check">
                      I have read and agree to the
                      <span> Terms </span> and <span> Conditions</span>
                    </label>
                  </div>
                  {termsValidation && (
                    <p className="errorcss">
                      Terms and Conditions are required
                    </p>
                  )}
                </div> */}

                <div className="terms-new">
                  <div className="terms">
                    <div class="checkbox-container">
                      <input
                        id="custom-checkbox"
                        checked={isChecked}
                        onChange={handleTerms}
                        className="input-field regular_checkbox"
                        type="checkbox"
                        placeholder="Enter the referral ID (optional)"
                      />
                      <label htmlFor="custom-checkbox"></label>
                    </div>
                    <label htmlFor="custom-checkbox" className="terms-check ">
                      I have read and agree to the
                      <Link to="/terms" className="text-yellow">
                        {" "}
                        Terms & Conditions{" "}
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacy" className="text-yellow">
                        {" "}
                        Privacy Policy
                      </Link>
                    </label>
                  </div>

                  {termsValidation && (
                    <p className="errorcss">
                      Terms and Conditions are required
                    </p>
                  )}
                </div>

                <div className="Submit">
                  {buttonLoader == false ? (
                    <button onClick={formSubmit}>Register</button>
                  ) : (
                    <button>Loading ...</button>
                  )}
                </div>

                <div className="foot">
                  <p>
                    Already registered ? <Link to="/login">Login</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
