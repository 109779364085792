import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { setAuthorization } from "../core/service/axios";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { useAuth } from "./AuthContext";

import { Box, Modal } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const Login = () => {
  const { login } = useAuth();

  const initialFormValue = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { email, password } = formValue;

  useEffect(() => {
    // var token = localStorage.getItem("user_token");
    var token = sessionStorage.getItem("user_token");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...formValue, ...{ [name]: sanitizedValue } };
    setFormValue(formData);
    validate(formData);
  };

  const validate = async (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email is a required field!";
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address!";
      setemailValidate(true);
    } else if (values.email.length > 250) {
      errors.email = "Email address is too long!";
      setemailValidate(true);
    } else if (values.password == "") {
      setemailValidate(false);
      setpasswordValidate(true);
      errors.password = "Password is required !";
    }
    //  else if (values.password.length < 15 || values.password.length > 30) {
    //   setpasswordValidate(true);
    //   errors.password = "Password should not below 15 above 30 letters !";
    // } 
    else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least lower character !";
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least upper character !";
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at One digit character !";
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = "Please enter at least one special character !";
    } else {
      setemailValidate(false);
      setpasswordValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const handleClick = async () => {
    try {
      validate(formValue);
      if (
        emailValidateref.current === false &&
        passwordValidateref.current === false
      ) {
        setbuttonLoader(true);
        sessionStorage.setItem("useremail", formValue.email);
        var data = {
          apiUrl: apiService.signin,
          payload: formValue,
        };
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status === true) {
          showsuccessToast(resp.Message);
          if (resp.data.tfa == 0) {
            await setAuthorization(resp.data.token);
            sessionStorage.setItem("user_token", resp.data.token);
            sessionStorage.setItem("tfa_status", resp.data.tfa);
            sessionStorage.setItem("socketToken", resp.data.socketToken);
            sessionStorage.setItem("VTXToken", resp.data.VTXToken);
            // localStorage.setItem("user_token", resp.data.token);
            // localStorage.setItem("tfa_status", resp.data.tfa);
            // localStorage.setItem("socketToken", resp.data.socketToken);
            // localStorage.setItem("VTXToken", resp.data.VTXToken);
            navigate("/dashboard");
          } else {
            sessionStorage.setItem("user_email", formValue.email);
            // localStorage.setItem("user_email", formValue.email);
            navigate("/tfa");
          }
          formValue.email = "";
          formValue.password = "";
        } else {
          if (
            resp.Message ==
            "Your account is not activated. Please verify to continue"
          ) {
            handleOpen();
            setbuttonLoader(false);
          }else{
            formValue.password = "";
            setbuttonLoader(false);
            showerrorToast(resp.Message);
          }
        }
      } else {
        validate(formValue);
      }
    } catch (err) {
      console.log(err, "=======login submit error=====");
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [verifyLoader,setverifyLoader]= useState(false);

  const verify_email = async () => {
    try {
      var obj = {
        email: sessionStorage.getItem("useremail"),
      };
      var data = {
        apiUrl: apiService.resendCode,
        payload: obj,
      };
      setverifyLoader(true);
      var resp = await postMethod(data);
      setverifyLoader(false);
      if (resp.status) {
        showsuccessToast(resp.Message);
        navigate("/verification");
      } else {
        showerrorToast(resp.Message);
      }
    } catch (error) {
      
    }
  }

  return (
    <>
      <main className="fidex_landing_main">
        <section>
          <Header />
        </section>
      </main>
      {/* <Header /> */}
      {/* <div className="container login-logo-wrapper">
        <Link to="/">
          <img src={Logo} alt="" className="login-logo" />
        </Link>
      </div> */}

      <div>
        <div className="register">
          <div className="container">
            <div className="row login-container">
              <div className="col-lg-6 left-reg ">
                <div className="reg-left-flex">
                  <div className="log-left-title">
                    Securely Access Your Trading World.
                  </div>
                  <div className="log-gift">
                    <img
                      src={require("../assets/login-mobile.webp")}
                      alt="gift-icon"
                    />
                  </div>
                  <div className="reg-left-content">
                    Log in to manage your trades and stay on top of the market.
                    Your secure trading hub is just a click away.
                  </div>
                </div>
              </div>
              <div className="col-lg-6 right-reg">
                <span class="heading">Welcome Back</span>
                {/* <div class="head-log">Log In with your Email</div> */}
                <form onSubmit={(e) => e.preventDefault()} action="">
                  <div className="input-groups  mt-4">
                    <h6 className="input-label mb-3">Email</h6>
                    <input
                      type="text"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      className="input-field"
                      placeholder="Please enter your email address"
                    />
                    {validationnErr && validationnErr.email && (
                      <p className="errorcss">{validationnErr.email}</p>
                    )}
                  </div>

                  <div className="input-groups icons mt-4">
                    <h6 className="input-label mb-3">Password</h6>

                    <input
                      type={inputType}
                      name="password"
                      value={password}
                      // minLength={15}
                      minLength={6}
                      maxLength={30}
                      onChange={handleChange}
                      className="input-field"
                      placeholder="Please enter your password"
                    />

                    {passHide == true ? (
                      <i
                        class="fa-regular fa-eye reg_eye"
                        onClick={() => passwordHide("show")}
                      ></i>
                    ) : (
                      <i
                        class="fa-regular fa-eye-slash reg_eye"
                        onClick={() => passwordHide("hide")}
                      ></i>
                    )}
                  </div>
                  {validationnErr && validationnErr.password && (
                    <p className="errorcss">{validationnErr.password}</p>
                  )}
                  {/* show error message */}

                  <div className="terms my-4">
                    <p>
                      <Link to="/forgotpassword">Forget password?</Link>
                    </p>
                  </div>
                  {buttonLoader === true ? (
                    <div className="Submit">
                      <button>Loading...</button>
                    </div>
                  ) : (
                    <div className="Submit">
                      <button onClick={handleClick}>Login</button>
                    </div>
                  )}
                </form>
                <div className="foot">
                  <p>
                    Don't have an account? <Link to="/register">Register</Link>
                  </p>
                </div>
              </div>
              {/* <div className="Submit">
                <button onClick={handleOpen}>Login</button>
              </div> */}

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="email-popup-modal-title"
                aria-describedby="email-popup-modal-description"
              >
                <Box>
                  <div className="email-popup-card">
                    <div className="email-pop-icon">
                      <i class="fa-regular fa-circle-xmark" onClick={handleClose}></i>
                    </div>
                    <div className="email-pop-img">
                      <img
                        src={require("../assets/icons/email-pop.webp")}
                        alt="email-icon"
                      />
                    </div>
                    <h3>E-mail Verification Require!</h3>
                    <p>
                      Please verify your email address to log in. Click the link
                      to verify now
                    </p>
                    <div className="Submit">
                    {verifyLoader ==false ?
                      <button onClick={verify_email}>Verify Now</button>
                      :
<button>Loading ...</button>
                    }
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
