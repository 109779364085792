import React, { useEffect } from "react";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";

const Footer = () => {
  const [siteLoader, setSiteLoader] = useState(false);
  const [siteData, setSiteData] = useState("");
  const [siteStatus, setSiteStatus] = useState("");

  useEffect(() => {
    getSitedata();
  }, []);

  const getSitedata = async () => {
    try {
      var data = {
        apiUrl: apiService.getSitedata,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      if (resp.status == true) {
        setSiteData(resp.data);
        setSiteStatus(resp.data.siteStatus);
        console.log(resp, "=-=-=get site datas =-=-", resp.data.depositStatus);
      }
    } catch (error) {}
  };

  return (
    <footer className="footer_content_section">
      <div className="container">
        <div className="row footer-row">
          <div className="col-lg-5">
            <div className="footer_content">
              {/* <img src={require("../assets/footer_logo.webp")} /> */}
              <img src={siteData.siteLogo} />
              {/* <p>
                Our platform offers advanced tools, secure transactions, and
                seamless access to global markets. Join us and take your trading
                to the next level.
              </p> */}
              <p>{siteData.footerContent}</p>
              <div>
                {" "}
                <div className="terms-address mb-4">
                  <p>VOLTCRYPT NETWORKING PRIVATE LIMITED.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 services_content">
            <div className="services">
              <h3>About US</h3>
              <li>
                <a href="/terms" target="_blank">
                  Terms
                </a>
              </li>
              <li>
                <a href="/privacy" target="_blank">
                  Privacy
                </a>
              </li>{" "}
              <li>
                <a href="/refundpolicy" target="_blank">
                  Refund Policy
                </a>
              </li>
              <li>
                <a href="/returnpolicy" target="_blank">
                  Return Policy
                </a>
              </li>
            </div>
            <div className="services">
              <h3>Products</h3>
              <li>
                <Link to="/trade/BTC_USDT">Trade</Link>
              </li>
              <li>
                <Link to="/p2p">P2P</Link>
              </li>
              <li>
                <Link to="/refferal">Earn</Link>
              </li>
              <li>
                <Link to="/swap">Swap</Link>
              </li>
            </div>
            <div className="services">
              <h3>Support</h3>
              <li>
                <Link to="/support" href="">
                  Support Center
                </Link>
              </li>
              <li>
                <Link to="">Contact Us</Link>
              </li>
              <li>
                <Link to="">24/7 Chat Support</Link>
              </li>
            </div>
            <div className="services">
              <h3>Business</h3>
              <li>
                <Link to="">Token Listing</Link>
              </li>
              <li>
                <Link to="">Affiliate Program</Link>
              </li>
              <li>
                <Link to="/airdroptokens">Airdrop</Link>
              </li>
            </div>
          </div>
          <div className="social-links">
            <div className="social-community">
              <h6>Community</h6>
              <div className="right-social-links">
                <a>
                  <img
                    src={require("../assets/icons/telegram.webp")}
                    alt="telegram"
                  />
                </a>
                <a>
                  <img
                    src={require("../assets/icons/gmail.webp")}
                    alt="gmail"
                  />
                </a>
                <a>
                  <img
                    src={require("../assets/icons/instagram.webp")}
                    alt="instagram"
                  />
                </a>
                <a>
                  <img
                    src={require("../assets/icons/coinmarket.webp")}
                    alt="coinmarket"
                  />
                </a>
                <a>
                  <img
                    src={require("../assets/icons/youtube.webp")}
                    alt="youtube"
                  />
                </a>
                <a>
                  <img
                    src={require("../assets/icons/reddit.webp")}
                    alt="reddit"
                  />
                </a>
                <a>
                  <img
                    src={require("../assets/icons/linkedin.webp")}
                    alt="linkedin"
                  />
                </a>
                <a>
                  <img
                    src={require("../assets/icons/coingeckgo.webp")}
                    alt="coindeck"
                  />
                </a>
              </div>
            </div>

            <div>
              {" "}
              <span className="terms-email">
                Contact us: voltrixcrypt@gmail.com
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights">
        &copy; {siteData.copyrightText}
        {/* 2024 Voltrix Crypt. All Rights Reserved. */}
      </div>
    </footer>
  );
};

export default Footer;
