import { Link } from "react-router-dom";

const UnderMaintanence = () => {
  return (
    <>
      <div className="page-not-found">
        <div className="maintanence-img-wrap">
          <img src={require("../assets/maintanence.webp")} alt="maintanence" />
        </div>
        <h3 className="not-found-title">This Page is Under Maintenance</h3>
        <p className="not-found-content">
          We’re currently working on some improvements. Please check back{" "}
          <div className="page-inline">soon. Thank you for your patience!</div>
        </p>
        <Link to="/" className="not-found-btn">
          Back To Home Page
        </Link>
      </div>
    </>
  );
};

export default UnderMaintanence;
